body {
  padding: 0;
  margin: 0;
}
#warp {
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.warper {
  padding-left: 5px;
  padding-right: 5px;
}

.heading {
  margin-top: 10px;
  padding: 3px;
  background-image: url(../public/bg.png);
  box-shadow: 0px 0px 3px;
  border: 1px solid #888;
  margin-bottom: 20px;
}
h1,
h2 {
  text-align: center;
  color: #000;
  font-weight: 500;
  font-size: 17px;
  line-height: 1em;
}
#head {
  font-weight: 700;
  font-size: 20px;
}
.head2 {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.04em;
}
.logo {
  width: 80px !important;
  height: 80px !important;
  box-shadow: 0px 0px 10px;
  border-radius: 15%;
  margin-top: 20px;
}

.btn-primary:hover,
.btn-primary:active {
  font-size: 22px;
  text-shadow: 0px 0px 5px #000;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: 0px 0px 10px #333;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
  text-shadow: 0px 0px 3px #000;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 10px 25px;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 15px;
}
.inputurl {
  display: block;
  width: 78%;
  height: 40px;
  padding: 3px 5%;
  font-size: 20px;
  line-height: 1.42857143;
  color: #000;
  background-color: #fff;
  background-image: none;
  border: 1px solid #888;
  border-radius: 25px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  margin-bottom: 10px;
}
.footer {
  width: 100%;
  min-height: 200px auto;
}
.browser {
  width: 100%;
  height: 200px;
}
